<template>
	<b-modal
		v-model="isOpen"
		hide-footer
		ref="modal"
		header-class="header-class-modal-doc-package"
		size="xl"
		ok-variant="success"
		cancel-variant="light"
		:title="rendModalTitle"
		@hidden="handleCloseModal"
		modal-class="mui-animation"
		:fade="false"
	>
		<div class="container-layout px-0 animated fadeIn">
			<div class="form" v-if="supplier">
				<!-- General information -->
				<div class="card-collapsible px-0">
					<div
						class="header-card-collapsible d-flex flex-row align-items-center cursor-pointer"
						@click="handleCollapsibleHeader('general-information')"
					>
						<div class="title w-90">
							<b-icon icon="info-circle" style="color: rgba(6, 38, 62, 0.84)" />
							<span class="ml-3">{{ FormMSG(21, 'General information') }}</span>
						</div>
						<div class="cursor-pointer d-flex justify-content-end w-10">
							<component v-if="!configAccordion['general-information'].isVisible" :is="getLucideIcon('ChevronDown')" color="#06263E" :size="22" />
							<component v-if="configAccordion['general-information'].isVisible" color="#06263E" :is="getLucideIcon('ChevronUp')" :size="22" />
						</div>
					</div>
				</div>
				<b-collapse :visible="configAccordion['general-information'].isVisible">
					<div class="content-card-collapsible">
						<b-row>
							<b-col sm="12" md="12" lg="6" xl="6" v-if="filterableByCountry">
								<b-form-group :label="FormMSG(122332, 'Country')" label-for="supplier-country-filter">
									<CountrySelector
										v-model="countryKeyFilter"
										custom-id="supplier-country-filter"
										:country-selected="countryKeyFilter"
										@change="handleCountryChange"
									/>
								</b-form-group>
							</b-col>
							<b-col sm="12" md="12" lg="6" xl="6">
								<b-form-group :label="FormMSG(343, 'Name')" label-for="new-supplier-name">
									<b-form-input
										id="new-supplier-name"
										v-model="supplier.name"
										type="text"
										required
										:placeholder="FormMSG(1113, 'Name')"
										:class="{ 'is-invalid': $v.supplier.name.$error }"
									/>
									<div v-if="submitted && !$v.supplier.name.required" class="invalid-feedback">
										{{ FormMSG(65, 'Please, length should be at least 2') }}
									</div>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col sm="12" md="12" lg="6" xl="6">
								<GoogleAutoComplete
									id="new-supplier-name"
									ref="google-search-supplier"
									:key="`google_auto_complete_${countryKeyFilter}`"
									:put-search="searchAddressFromVat"
									:label="FormMSG(1115, 'Internet search')"
									:country.sync="googleAutoCompleteRestrictedCountry"
									@placechanged="handleAutoCompleteChange"
								/>
							</b-col>
							<b-col sm="12" md="12" lg="6" xl="6">
								<VatChecker v-model="supplier.vat" @change="handleVatChange" />
							</b-col>
						</b-row>
						<b-row class="d-flex justify-content-center mt-1" v-if="isEditable">
							<b-col sm="12" md="4" lg="4" xl="4">
								<b-button block variant="outline-danger" @click="handleDeleteSupplier">
									{{ FormMSG(1114, 'Delete') }}
								</b-button>
							</b-col>
						</b-row>
					</div>
				</b-collapse>
				<!-- Address -->
				<div class="card-collapsible px-0 mt-3 mb-1">
					<div class="header-card-collapsible d-flex flex-row align-items-center cursor-pointer" @click="handleCollapsibleHeader('address')">
						<div class="title w-90">
							<b-icon icon="geo-fill" style="color: rgba(6, 38, 62, 0.84)" />
							<span class="ml-3">{{ FormMSG(22, 'Address') }}</span>
						</div>
						<div class="cursor-pointer d-flex justify-content-end w-10">
							<component v-if="!configAccordion['address'].isVisible" :is="getLucideIcon('ChevronDown')" color="#06263E" :size="22" />
							<component v-if="configAccordion['address'].isVisible" :is="getLucideIcon('ChevronUp')" color="#06263E" :size="22" />
						</div>
					</div>
				</div>
				<b-collapse :visible="configAccordion['address'].isVisible">
					<div class="content-card-collapsible">
						<AddressForm
							ref="supplier-address"
							:auto-completed-data="handleAutoCompleteAddressFromName"
							:edit-data="currAddress"
							:fill-search="searchAddress"
							:filterable-by-country="false"
							:country-key.sync="countryKeyFilter"
							:has-country-key-parent-filter="filterableByCountry"
							:form-validation="$v.addressPayload"
							:form-submitted.sync="submitted"
							@change="handleAddress"
						/>
					</div>
				</b-collapse>
				<!-- Data -->
				<div class="card-collapsible px-0 mt-3 mb-1">
					<div class="header-card-collapsible d-flex flex-row align-items-center cursor-pointer" @click="handleCollapsibleHeader('data')">
						<div class="title w-90">
							<b-icon icon="person-lines-fill" style="color: rgba(6, 38, 62, 0.84)" />
							<span class="ml-3">{{ FormMSG(23, 'Data') }}</span>
						</div>
						<div class="cursor-pointer d-flex justify-content-end w-10">
							<component v-if="!configAccordion['data'].isVisible" :is="getLucideIcon('ChevronDown')" color="#06263E" :size="22" />
							<component v-if="configAccordion['data'].isVisible" :is="getLucideIcon('ChevronUp')" color="#06263E" :size="22" />
						</div>
					</div>
				</div>
				<b-collapse :visible="configAccordion['data'].isVisible">
					<div class="content-card-collapsible">
						<b-row>
							<b-col
								sm="12"
								:md="`${supplier.companyType !== 0 ? 6 : 12}`"
								:lg="`${supplier.companyType !== 0 ? 6 : 12}`"
								:xl="`${supplier.companyType !== 0 ? 6 : 12}`"
							>
								<b-form-group :label="FormMSG(16, 'Company type')" label-for="supplier-input-company-type">
									<b-form-select :options="companyTypes" v-model="supplier.companyType" @change="handleCompanyType"></b-form-select>
								</b-form-group>
							</b-col>
							<b-col sm="12" md="6" lg="6" xl="6" v-if="supplier.companyType !== 0">
								<b-form-group :label="FormMSG(17, 'Contact name')" label-for="supplier-input-contact-name">
									<b-form-input
										id="supplier-input-contact-name"
										v-model="supplier.contactName"
										type="text"
										required
										:placeholder="FormMSG(18, 'Contact name...')"
									/>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col sm="12" md="6" lg="6" xl="6">
								<b-form-group :label="FormMSG(10, 'Phone')" label-for="supplier-input-phone">
									<b-form-input
										id="supplier-input-phone"
										v-model="supplier.phone"
										type="text"
										required
										:placeholder="FormMSG(30, 'Phone...')"
									/>
								</b-form-group>
							</b-col>
							<b-col sm="12" md="6" lg="6" xl="6">
								<b-form-group :label="FormMSG(11, 'Email')" label-for="supplier-input-email">
									<b-form-input
										id="supplier-input-email"
										v-model="supplier.email"
										type="email"
										required
										:placeholder="FormMSG(29, 'Email...')"
									/>
								</b-form-group>
							</b-col>
						</b-row>
					</div>
				</b-collapse>
				<!-- Bank @TODO -->
				<div class="card-collapsible px-0 mt-3 mb-1">
					<div class="header-card-collapsible d-flex flex-row align-items-center cursor-pointer" @click="handleCollapsibleHeader('bank')">
						<div class="title w-90">
							<b-icon icon="bank2" style="color: rgba(6, 38, 62, 0.84)" />
							<span class="ml-3">{{ FormMSG(24, 'Bank') }}</span>
						</div>
						<div class="cursor-pointer d-flex justify-content-end w-10">
							<component v-if="!configAccordion['bank'].isVisible" :is="getLucideIcon('ChevronDown')" color="#06263E" :size="22" />
							<component v-if="configAccordion['bank'].isVisible" :is="getLucideIcon('ChevronUp')" color="#06263E" :size="22" />
						</div>
					</div>
				</div>
				<b-collapse :visible="configAccordion['bank'].isVisible">
					<div class="content-card-collapsible">
						<div v-if="supplier.supplierBankAccounts && supplier.supplierBankAccounts.length > 0">
							<b-row v-for="(bankItem, i) in supplier.supplierBankAccounts" :key="i">
								<b-col class="p-0 m-0">
									<bank-form
										:item="bankItem"
										:index="i"
										:supplier="{
											id: +supplier.id,
											index: 0
										}"
										@supplier-bank-form:removed="removeBank"
										@finance-bank-form:updated="onLineBankChanged"
										class="p-0 m-0"
									/>
								</b-col>
							</b-row>
						</div>
						<b-row v-else>
							<b-col class="text-center py-2 text-empty-data">
								{{ FormMSG(33, 'No data found') }}
							</b-col>
						</b-row>
						<b-row class="pb-3">
							<b-col cols="12" class="d-flex justify-content-end">
								<b-button
									size="sm"
									variant="outline-primary"
									class="w-200-px d-flex justify-content-center align-items-center"
									block
									@click="addNewBank()"
								>
									<component :is="getLucideIcon('PlusCircle')" :size="18" :stroke-width="2" />
									<div class="px-2" style="margin-bottom: -2px">
										{{ FormMSG(34, 'Add new bank') }}
									</div>
								</b-button>
							</b-col>
						</b-row>
						<!-- <b-row>
							<b-col sm="12" md="6" lg="6" xl="6">
								<b-form-group :label="FormMSG(14, 'Iban')" label-for="supplier-input-iban">
									<b-form-input
										id="supplier-input-iban"
										v-model="supplier.supplierBankAccount.iban"
										required
										:placeholder="FormMSG(31, 'Iban...')"
									/>
								</b-form-group>
							</b-col>
							<b-col sm="12" md="6" lg="6" xl="6">
								<b-form-group :label="FormMSG(15, 'BIC')" label-for="supplier-input-bic">
									<b-form-input
										id="supplier-input-bic"
										v-model="supplier.supplierBankAccount.bic"
										required
										:placeholder="FormMSG(32, 'BIC...')"
									/>
								</b-form-group>
							</b-col>
						</b-row> -->
					</div>
				</b-collapse>
			</div>
		</div>
		<div class="content_sb_middle_end clearfix full push_t_30">
			<b-button pill variant="light" @click="handleCloseModal">
				{{ FormMSG(2, 'Cancel') }}
			</b-button>

			<b-button pill class="push_l_10" variant="primary" @click="handleSubmitModal">
				{{ FormMSG(1, 'Save') }}
			</b-button>
		</div>
	</b-modal>
</template>

<script>
import { omit, isNil, getObjectFromValue } from '@/shared/utils';
// import { myProject } from '@/cruds/project.crud'
import CountrySelector from '@/components/Selectors/CountrySelector';
import AddressForm from '@/components/Forms/Address';
import GoogleAutoComplete from '@/components/GoogleAutoComplete';
import VatChecker from '@/components/VatChecker';
import { store } from '@/store';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import BankForm from '@/components/Finance/BankForm';
import { delSupplierBankAccount } from '@/cruds/suppliers.crud';
import _ from 'lodash';

export default {
	name: 'AddressFormModalContainerComponent',
	components: {
		CountrySelector,
		AddressForm,
		GoogleAutoComplete,
		VatChecker,
		BankForm
	},
	mixins: [languageMessages, globalMixin, validationMixin],
	props: {
		value: {
			type: Boolean,
			required: true,
			default: false
		},
		focusSupplier: {
			type: Object,
			required: false,
			default: null
		},
		editable: {
			type: Boolean,
			required: false,
			default: false
		},
		filterableByCountry: {
			type: Boolean,
			required: false,
			default: true
		},
		currentSearch: {
			type: String,
			required: false,
			default: ''
		},
		supplierCandidate: {
			type: Object,
			required: false,
			default: () => {}
		},
		title: {
			type: String,
			required: false,
			default: null
		}
	},
	data() {
		return {
			supplier: Object.assign({}, this.supplierCandidate),
			addressPayload: {},
			// adressState: false,
			handleAutoCompleteAddressFromName: null,
			searchAddressFromVat: (this.supplierCandidate && this.supplierCandidate.address) || null,
			searchAddress: '',
			countryKeyFilter: 16965, // Belgium's countryKey
			googleAutoCompleteRestrictedCountry: 'BE',
			configAccordion: {
				'general-information': {
					isVisible: true
				},
				address: {
					isVisible: true
				},
				data: {
					isVisible: false
				},
				bank: {
					isVisible: false
				}
			},
			submitted: false,
			isValidBanks: [],
			addUpdBanks: []
		};
	},
	computed: {
		/**
		 * @return {Boolean}
		 */
		isOpen: {
			get() {
				return this.value;
			},
			set(val) {
				return val;
			}
		},
		/**
		 * @return {Object}
		 */
		currAddress() {
			const fs = this.focusSupplier;
			if (isNil(fs)) return null;
			return isNil(fs.address) ? null : fs.address;
		},
		/**
		 * @return {Boolean}
		 */
		isEditable() {
			return this.editable;
		},
		optionsForCountries() {
			return this.FormMenu(8);
		},
		optionsForCountryCode() {
			return this.FormMenu(9);
		},
		/**
		 * @return {String}
		 */
		rendModalTitle() {
			return isNil(this.title) ? this.FormMSG(1111, 'Add entity address') : this.title;
		},
		companyTypes() {
			return this.FormMenu(1325);
		}
	},
	watch: {
		focusSupplier: {
			/**
			 * @param {Object}
			 */
			handler(supplier) {
				if (!isNil(supplier)) {
					this.initEdit(supplier);
					if (!_.isNil(this.supplier.supplierBankAccounts) && this.supplier.supplierBankAccounts.length > 0) {
						this.supplier.supplierBankAccounts.forEach(() => {
							this.isValidBanks.push(true);
						});
					}
				}
			},
			deep: true,
			immediate: true
		},
		supplierCandidate(supplierCandidate) {
			this.supplier = {
				...this.supplier,
				...supplierCandidate
			};
			if (supplierCandidate.address) {
				this.searchAddressFromVat = this.searchAddress = supplierCandidate.address;
			}
		},
		/**
		 * @param {String} name
		 */
		'supplier.name'(name) {
			this.handleSupplierNameChange(name);
		}
	},
	mounted() {
		this.initCurrSearch();
		this.initCountrySearch();
		let focusSupplier = this.focusSupplier.companyType === 0 ? { ...this.focusSupplier, companyType: 1 } : { ...this.focusSupplier };
		this.initEdit({ ...focusSupplier, ...this.supplierCandidate });
	},
	methods: {
		initCurrSearch() {
			if (this.currentSearch === '') return;
			this.supplier.name = this.currentSearch;
		},
		async initCountrySearch() {
			// const project = await myProject()
			if (isNil(store.state) || isNil(store.state.myProject)) return;

			const project = store.state.myProject;
			/**
			 * @TODO
			 * Filter legalEntity whith "isOwner" when it's became more than one
			 */
			const legalEntity = project.legalEntities.length > 0 ? project.legalEntities[0] : null;

			if (!isNil(this.supplier.address) && this.supplier.address.countryKey > 0) {
				this.countryKeyFilter = this.supplier.address.countryKey;
				this.googleAutoCompleteRestrictedCountry = this.supplier.address.countryCode;
			} else if (!isNil(legalEntity) && !isNil(legalEntity.address) && legalEntity.address.countryKey > 0) {
				this.countryKeyFilter = legalEntity.address.countryKey;
				this.googleAutoCompleteRestrictedCountry = legalEntity.address.countryCode;
			}
		},
		initDefaultRestrictedCountry() {
			if (!this.filterableByCountry) return;
			const key = this.countryKeyFilter;
			this.googleAutoCompleteRestrictedCountry = this.getCountryIsoFromKey(key);
		},
		/**
		 * @param {Object} supplier
		 */
		initEdit(supplier) {
			this.supplier = _.cloneDeep(supplier);
		},
		handleCloseModal() {
			this.$emit('input', false);
		},
		handleDeleteSupplier() {
			if (!this.isEditable) return;

			this.$bvModal
				.msgBoxConfirm(this.FormMSG(25, 'Are you sure to remove this supplier ?'), {
					title: this.FormMSG(26, 'Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(27, 'YES'),
					cancelTitle: this.FormMSG(28, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then((v) => {
					if (!v) return;
					this.$emit('delete-supplier', this.supplier);
				})
				.catch((err) => console.log(err));
		},
		/**
		 * @param {String} name
		 */
		handleSupplierNameChange(name) {
			this.$emit('supplier-name-change', name);
		},
		/**
		 * @param {Object} address
		 */
		handleAddress(address) {
			this.addressPayload = address;
			// console.log('handleAddress')
			// this.countryKeyFilter = address.countryKey
		},
		handleSubmitModal() {
			// const payload = {
			// 	...this.supplier,
			// 	address: this.addressPayload
			// }
			this.submitted = true;

			this.$v.$touch();
			if (this.$v.$invalid || !this.validBanks()) {
				console.log({ isValidBanks: this.isValidBanks, validBanks: this.validBanks() });
				this.createToastForMobile(
					this.FormMSG(96869, 'Error'),
					this.FormMSG(5675, 'Please verify, some fields are required or format is not valid.'),
					'',
					'danger'
				);

				return;
			}

			this.addressPayload = {
				...this.addressPayload,
				countryKey: this.countryKeyFilter
			};
			this.$emit('submit', {
				...this.supplier,
				address: this.addressPayload,
				banks: this.addUpdBanks
			});

			this.handleCloseModal();
		},
		/**
		 * @param {Object} value
		 */
		handleAutoCompleteChange(value) {
			this.supplier = {
				...this.supplier,
				name: isNil(this.supplier.name) || this.supplier.name === '' ? value.name : this.supplier.name
			};
			this.handleAutoCompleteAddressFromName = {
				...this.addressPayload,
				street: value.route,
				city: value.locality,
				number: value.street_number,
				state: value.administrative_area_level_1,
				zip: value.postal_code,
				countryKey: value.country_key,
				countryCode: value.country_code,
				country: value.country,
				lat: value.latitude,
				lng: value.longitude
			};
		},
		/**
		 * @param {Object} vat
		 */
		handleVatChange(vat) {
			this.supplier = {
				...this.supplier,
				vat: vat.originVat,
				name: vat.name
			};
			if (isNil(vat.address)) return;
			// this.searchAddressFromVat = vat.address
			this.searchAddress = vat.address;
			// this.adressState = true

			if (!isNil(vat.countryCode) && vat.countryCode != '') {
				const country = getObjectFromValue(this.optionsForCountryCode, 'text', vat.countryCode);
				this.countryKeyFilter = country.value;
			}
		},
		/**
		 * @param {Object} country
		 */
		handleCountryChange(country) {
			const newKey = country.key.value;

			this.googleAutoCompleteRestrictedCountry = this.getCountryIsoFromKey(newKey);
			this.countryKeyFilter = newKey;

			this.$refs['google-search-supplier'].clearSelected();
			// this.supplier.name = '';
			// this.supplier.vat = '';
			this.$refs['supplier-address'].clearData();
			// this.handleAutoCompleteAddressFromName = {};
		},
		getCountryIsoFromKey(key = 16965) {
			const country = getObjectFromValue(this.optionsForCountryCode, 'value', key);
			return country ? country.text.toLowerCase() : '';
		},
		handleCompanyType(payload) {
			this.supplier.companyType = payload;
		},
		handleCollapsibleHeader(key) {
			this.configAccordion[key].isVisible = !this.configAccordion[key].isVisible;
		},
		addNewBank() {
			const bank = {
				id: 0,
				bankName: '',
				iban: '',
				bic: '',
				currency: 0
			};

			if (_.isNil(this.supplier.supplierBankAccounts)) {
				this.$set(this.supplier, 'supplierBankAccounts', []);
			}

			this.supplier.supplierBankAccounts.push(bank);
			this.isValidBanks.push(false);
		},
		removeBank({ id, index }) {
			const updateVariables = (index) => {
				this.supplier.supplierBankAccounts.splice(index, 1);

				const idx = this.addUpdBanks.findIndex((bank) => bank.index === index);
				if (idx !== -1) {
					this.addUpdBanks.splice(idx, 1);
				}

				this.isValidBanks.splice(index, 1);
			};

			if (+id === 0) {
				updateVariables(index);
			} else {
				const action = async () => {
					await delSupplierBankAccount(id)
						.then((result) => {
							if (result) {
								updateVariables(index);
							}
						})
						.catch((e) => {
							console.error({ e });
						});
				};
				this.confirmModal(action, this.FormMSG(35, 'Are you sure to remove this bank account ?'), 'danger');
			}
		},
		onLineBankChanged({ index, item, isLineValid }) {
			if (this.addUpdBanks.length === 0) {
				this.addUpdBanks.push({
					...item,
					index
				});
			} else {
				const idx = this.addUpdBanks.findIndex((bank) => bank.index === index);
				if (idx === -1) {
					this.addUpdBanks.push({
						...item,
						index
					});
				} else {
					this.addUpdBanks[idx] = Object.assign(this.addUpdBanks[idx], item);
				}
			}

			this.isValidBanks[index] = isLineValid;
		},
		validBanks() {
			if (this.isValidBanks.length === 0) return true;
			const isValidBanks = this.isValidBanks.join(',');

			return !/false/.test(isValidBanks);
		}
	},
	validations: {
		supplier: {
			name: {
				required,
				min: minLength(2)
			}
		},
		addressPayload: {
			state: {
				required,
				min: minLength(2)
			},
			city: {
				required,
				min: minLength(2)
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.text-empty-data {
	font-size: 1.1rem;
	color: rgba(6, 38, 62, 0.64);
	font-weight: 600;
	letter-spacing: 0.05rem;
}
</style>
